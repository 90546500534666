<template>
  <div>
    <WsUpdate
      ref="WsUpdate"
      :modelName="modelName"
      :id="_id"
      :label="label"
      :fields="fields"
      :primary="pageSetting.primary"
      :secondary="pageSetting.secondary"
      titleKey="name"
      :value="state"
      @input="$_onInput($event)"
      @modelDataInit="$_onModelDataInit($event)"
      :emitSubmit="true"
      :emitInput="true"
      @submit="$_onSubmit()"
    >
    </WsUpdate>
    <WsMain v-if="state&&state.fields">
      <WsCard>
        <WsText size="5">{{$t('section_content')}}</WsText>
        <WsState
          class="mt-20"
          type="form"
          :fields="state.fields"
          v-model="state.content"
        ></WsState>
      </WsCard>
    </WsMain>
  </div>
</template>

<script>
import model from "@/__vue2stone_cms/models/tulpa_section";
export default {
  metaInfo() {
    return {
      title: `${this.$t("edit")}${this.$t("page_section")}`,
    };
  },
  data: () => ({
    aa: {
      logo: { label: "Logo", type: "image" },
      menu: {
        label: "Menu",
        type: "list",
        fields: {
          text: {
            label: "Title",
            type: "text",
            placeholder: null,
            rules: "required",
            id: null,
          },
          to: {
            label: "Link",
            type: "text",
            placeholder: null,
            rules: null,
            id: null,
          },
        },
        textKey: "text",
        id: null,
      },
      logoLink: { label: "Logo Link", type: "text" },
      headerRightButtonLink: { label: "Right Button Link", type: "text" },
      headerRightButtonText: { label: "Right Button Text", type: "text" },
      langText: { label: "Language Text", type: "text" },
      langItems: {
        label: "Language Items",
        type: "list",
        fields: {
          text: { label: "Text", type: "text" },
          to: { label: "link", type: "text" },
          is_big_text: { label: "Big Text", type: "switch" },
        },
        textKey: "text",
        id: null,
      },
    },
    state: {},
    modelName: model.modelName,
    label: model.label,
    fields: model.fields,
    pageSetting: {
      primary: [
        {
          type: "stateCard",
          floors: [
            {
              fields: ["name", "component_name", "fields"],
            },
          ],
        },
      ],
      secondary: [
        {
          type: "stateCard",
          floors: [
            {
              title: "項目設定",
              titleInLocale: true,
              fields: ["tags", "remark"],
            },
          ],
        },
      ],
    },
  }),

  methods: {
    $_onInput($event) {
      this.state = {
        ...this.state,
        ...$event,
      };
    },
    $_onModelDataInit($event) {
      this.state = {
        ...$event,
      };
    },
    async $_onSubmit() {
      const postData = { ...this.state };
      postData.content = btoa(
        unescape(encodeURIComponent(JSON.stringify(this.state.content)))
      );
      try {
        this.$refs.WsUpdate.loadingStart();
        await this.$axios.patch(`tulpa_section/${this._id}`, postData);
        this.$router.push("/tulpa_section");
      } catch (error) {
        console.error(error);
        alert("儲存錯誤");
      } finally {
        this.$refs.WsUpdate.loadingStop();
      }
    },
  },

  computed: {
    _id() {
      return this.$route.params.id;
    },
  },
};
</script>

<style>
</style>